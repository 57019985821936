import { EMAIL_REGEX } from 'app/utilities'

export default {
  email: {
    slug: 'email',
    inputType: 'email',
    placeholder: 'Email Address',
    required: true,
    hideError: true,
    hideLabel: true,
    validate: (value) => {
      if (!EMAIL_REGEX.test(value)) return 'Invalid email'
      return null
    }
  }
}
