import { css } from 'styled-components'

import { Breakpoints, Sizes } from 'app/styles'

export const pageWrap = css`
  display: flex;
  justify-content: center;
  padding: 0 ${Sizes.xAppPadding};

  @media(max-width: ${Breakpoints.main}) {
    padding: 0 ${Sizes.xAppPaddingMobile};
  }
`

export const maxWidth = css`
  max-width: 800px;
`
