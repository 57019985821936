import styled, { css } from 'styled-components'

import { Breakpoints, Type } from 'app/styles'
import { Button, TextField } from 'app/ui-kit'

export const transitionDurationMs = 300

export const BetaSignUpComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Wrap = styled.div`
  position: relative;
  width: 340px;

  @media(max-width: ${Breakpoints.iphone5}) {
    width: 100%;
  }
`

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: ${Type.fontWeights.demiBold};
  margin-bottom: 16px;

  @media(max-width: ${Breakpoints.main}) {
    font-size: 16px;
    margin-bottom: 14px;
  }
`

export const FormWrap = styled.div`
  height: 106px;
`

export const Form = styled.div`
  position: absolute;
  width: 340px;

  @media(max-width: ${Breakpoints.iphone5}) {
    width: 100%;
  }

  &.form-enter {
    opacity: 0;
  }

  &.form-enter-active {
    opacity: 1;
    transition: opacity ${transitionDurationMs}ms ease;
  }

  &.form-exit {
    opacity: 1;
  }

  &.form-exit-active {
    opacity: 0;
    transition: opacity ${transitionDurationMs}ms ease;
  }
`

export const FormElements = styled.div`
  ${props => props.loading && css`
    opacity: 0.3;
  `}
`

export const LoadingWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledTextField = styled(TextField)`
  margin-bottom: 10px;
  text-align: left;
`
export const StyledButton = styled(Button)`
  width: 100%;
`

export const StyledPuffinRocket = styled.img`
  width: 127px;
  height: 67px;
  position: absolute;
  right: -84px;
  top: -36px;

  @media(max-width: ${Breakpoints.main}) {
    width: 83px;
    height: 44px;
    right: -12px;
    top: -24px;
  }
`

export const Success = styled.div`
  position: absolute;

  &.success-enter {
    opacity: 0;
  }

  &.success-enter-active {
    opacity: 1;
    transition: opacity ${transitionDurationMs}ms ease;
  }

  &.success-exit {
    opacity: 1;
  }

  &.success-exit-active {
    opacity: 0;
    transition: opacity ${transitionDurationMs}ms ease;
  }
`
