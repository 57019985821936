import styled from 'styled-components'

import { LogoLockup } from 'app/ui-kit/Icons'
import { Colors, Sizes, Breakpoints } from 'app/styles'

export const NavComponent = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px ${Sizes.xAppPadding};
  box-sizing: border-box;
  background-color: ${Colors.teal};
  box-sizing: border-box;

  @media(max-width: ${Breakpoints.main}) {
    padding: 30px ${Sizes.xAppPaddingMobile};
  }
`

export const MaxWidth = styled.div`
  max-width: ${Sizes.maxWidth};
  width: 100%;
  display: flex;
`

export const StyledLogoLockup = styled(LogoLockup)`
  width: 131px;
  height: 40px;

  @media(max-width: ${Breakpoints.main}) {
    width: 110px;
    height: 35px;
  }
`
