import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import * as S from './styles'

export default class Nav extends Component {
  render () {
    return (
      <S.NavComponent>
        <S.MaxWidth>
          <Link to='/'>
            <S.StyledLogoLockup />
          </Link>
        </S.MaxWidth>
      </S.NavComponent>
    )
  }
}
