import React, { Component } from 'react'
import { Terms } from '@all-turtles/components'

import * as S from './styles'
import { Footer } from 'app/components'

export default class TermsOfUse extends Component {
  render () {
    return (
      <S.TermsOfUsePage>
        <S.Wrap>
          <S.MaxWidth>
            <S.Title>Terms of use</S.Title>
            <Terms />
          </S.MaxWidth>
        </S.Wrap>
        <Footer />
      </S.TermsOfUsePage>
    )
  }
}
