import styled from 'styled-components'

import { Sizes, Templates } from 'app/styles'
import { Colors } from '@all-turtles/puffin-web-core'

export const SupportPage = styled.div`
  ${Templates.pageWrap};
  background: ${Colors.teal};
  min-height: 100vh;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-top: 50px;
  }

  li {
    margin-bottom: 12px;
  }
`

export const Wrap = styled.div`
  max-width: ${Sizes.maxWidth};
  width: 100%;
`

export const Title = styled.h1`
  max-width: 580px;
`

export const MaxWidth = styled.div`
  max-width: 800px;

  a {
    text-decoration: underline;
  }
`
