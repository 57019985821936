import React, { Component } from 'react'

import * as S from './styles'
import { Footer, BetaSignUp } from 'app/components'
import { puffinInvite, puffinTime } from 'app/assets/images'

export default class Home extends Component {
  render () {
    return (
      <S.HomePage>
        <S.MaxWidth>
          <S.StyledPuffinInvite src={puffinInvite} />
          <S.StyledPuffinTime src={puffinTime} />
          <S.Title>Schedule meetings without any back and forth communication</S.Title>
          <BetaSignUp />
        </S.MaxWidth>
        <Footer />
      </S.HomePage>
    )
  }
}
