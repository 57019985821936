import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Badge } from '@all-turtles/components'

import { Breakpoints, Colors, Sizes, Type } from 'app/styles'

export const FooterComponent = styled.div`
  margin-top: auto;
  padding-top: 100px;
  margin-bottom: 46px;
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 100px ${Sizes.xAppPadding} 0 ${Sizes.xAppPadding};
  box-sizing: border-box;

  @media(max-width: ${Breakpoints.main}) {
    padding: 100px ${Sizes.xAppPaddingMobile} 0 ${Sizes.xAppPaddingMobile};
  }
`

export const MaxWidth = styled.div`
  max-width: ${Sizes.maxWidth};
  width: 100%;
  display: flex;
  flex-direction: column;

  @media(max-width: ${Breakpoints.main}) {
    align-items: center;
  }
`

export const FooterNav = styled.div`
  display: flex;
  text-align: center;

  @media(max-width: ${Breakpoints.main}) {
    flex-direction: column;
    align-items: center;
  }
`

export const NavItems = styled.div``

const footerNavItemShared = css`
  margin-right: 24px;
  font-size: 16px;
  font-weight: ${Type.fontWeights.medium};
  color: ${Colors.black};

  &:last-child {
    margin-right: 0;
  }

  @media(max-width: ${Breakpoints.main}) {
    font-size: 14px;
  }
`

export const FooterNavItem = styled(Link)`
  ${footerNavItemShared};
`

export const StyledFooterA = styled.a`
  ${footerNavItemShared};
`

export const Copyright = styled.div`
  margin-left: auto;
  color: ${Colors.black};
  font-size: 16px;
  font-weight: ${Type.fontWeights.medium};

  @media(max-width: ${Breakpoints.main}) {
    margin-left: 0;
    text-align: center;
    font-size: 14px;
  }
`
export const StyledAllTurtlesBadge = styled(Badge)`
  @media(max-width: ${Breakpoints.main}) {
    display: none;
  }
`

export const StyledAllTurtlesBadgeMobile = styled(Badge)`
  width: 172px;
  height: 26px;
  display: none;

  @media(max-width: ${Breakpoints.main}) {
    margin-top: 23px;
    margin-bottom: 26px;
    display: block;
  }
`
export const Rule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.darkBlueGray};
  margin: 21px 0 24px 0;

  @media(max-width: ${Breakpoints.main}) {
    display: none;
  }
`
