exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var getUrl = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL___0___ = getUrl(require("../assets/fonts/AvenirNext-Regular.woff2"));
var ___CSS_LOADER_URL___1___ = getUrl(require("../assets/fonts/AvenirNext-Regular.woff"));
var ___CSS_LOADER_URL___2___ = getUrl(require("../assets/fonts/AvenirNext-Regular.ttf"));
var ___CSS_LOADER_URL___3___ = getUrl(require("../assets/fonts/AvenirNext-Medium.woff2"));
var ___CSS_LOADER_URL___4___ = getUrl(require("../assets/fonts/AvenirNext-Medium.woff"));
var ___CSS_LOADER_URL___5___ = getUrl(require("../assets/fonts/AvenirNext-Medium.ttf"));
var ___CSS_LOADER_URL___6___ = getUrl(require("../assets/fonts/AvenirNext-DemiBold.woff2"));
var ___CSS_LOADER_URL___7___ = getUrl(require("../assets/fonts/AvenirNext-DemiBold.woff"));
var ___CSS_LOADER_URL___8___ = getUrl(require("../assets/fonts/AvenirNext-DemiBold.ttf"));
var ___CSS_LOADER_URL___9___ = getUrl(require("../assets/fonts/AvenirNext-Bold.woff2"));
var ___CSS_LOADER_URL___10___ = getUrl(require("../assets/fonts/AvenirNext-Bold.woff"));
var ___CSS_LOADER_URL___11___ = getUrl(require("../assets/fonts/AvenirNext-Bold.ttf"));
// Module
exports.push([module.id, "@font-face {\n  font-family: 'Avenir Next';\n  src: local('Avenir Next Regular'),\n        local('AvenirNext-Regular'),\n        url(" + ___CSS_LOADER_URL___0___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___1___ + ") format('woff'),\n        url(" + ___CSS_LOADER_URL___2___ + ") format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Avenir Next';\n  src: local('Avenir Next Medium'),\n        local('AvenirNext-Medium'),\n        url(" + ___CSS_LOADER_URL___3___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___4___ + ") format('woff'),\n        url(" + ___CSS_LOADER_URL___5___ + ") format('truetype');\n  font-weight: 500;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Avenir Next';\n  src: local('Avenir Next Demi Bold'),\n        local('AvenirNext-DemiBold'),\n        url(" + ___CSS_LOADER_URL___6___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___7___ + ") format('woff'),\n        url(" + ___CSS_LOADER_URL___8___ + ") format('truetype');\n  font-weight: 600;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Avenir Next';\n  src: local('Avenir Next Bold'),\n        local('AvenirNext-Bold'),\n        url(" + ___CSS_LOADER_URL___9___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___10___ + ") format('woff'),\n        url(" + ___CSS_LOADER_URL___11___ + ") format('truetype');\n  font-weight: bold;\n  font-style: normal;\n}\n", ""]);
