import { Root } from 'app/components'
import { Faq, Home, Privacy, TermsOfUse, NotFound, Support } from 'app/pages'

export default [{
  component: Root,
  routes: [{
    path: '/',
    exact: true,
    component: Home,
    meta: {
      title: 'Puffin',
      description: 'We all hate meetings. But we don’t have to. Puffin improves meeting culture.'
    }
  }, {
    path: '/privacy',
    exact: true,
    component: Privacy,
    meta: {
      title: 'Puffin - Privacy',
      description: 'The Puffin Privacy Policy'
    }
  }, {
    path: '/terms-of-use',
    exact: true,
    component: TermsOfUse,
    meta: {
      title: 'Puffin - Terms of Use',
      description: 'The Puffin Terms of Use'
    }
  }, {
    path: '/faq',
    exact: true,
    component: Faq,
    meta: {
      title: 'Puffin - FAQ',
      description: 'Frequently asked questions about Puffin.'
    }
  }, {
    path: '/support',
    exact: true,
    component: Support,
    meta: {
      title: 'Puffin - Support',
      description: 'Puffin Support'
    }
  }, {
    path: '*',
    component: NotFound,
    meta: {
      title: 'Puffin - Not Found',
      description: '404 -  Not found'
    }
  }]
}]
