import React, { Component } from 'react'

import * as S from './styles'
import { Footer } from 'app/components'

const appDomain = process.env.APP_DOMAIN
const zoomUrl = 'https://marketplace.zoom.us/'
const helpEmail = 'help@meetwithpuffin.com'

export default class Support extends Component {
  render () {
    return (
      <S.SupportPage>
        <S.Wrap>
          <S.MaxWidth>
            <S.Title>Support</S.Title>
            <h3>Install Puffin</h3>
            <ul>
              <li>
                Puffin is currently in closed-beta, however if you already have access, you may run Puffin either on the web or on iPhone. We recommend iPhone to ensure that you have access to the latest features. If you don’t have access yet, feel free to join the waiting list <a href='/'>here.</a>
              </li>
            </ul>

            <h3>Connect Zoom</h3>
            <ul>
              <li>
                Log into the Puffin web app <a href={appDomain} rel='noopener noreferrer' target='_blank'>here</a>.
              </li>
              <li>
                Navigate to your integrations settings by clicking <a href={`${appDomain}/integrations`} rel='noopener noreferrer' target='_blank'>here</a>, or manually by opening your account menu in the top right and click "Integrations."
              </li>
              <li>Click "Zoom" and proceed to login in to your Zoom account.</li>
              <li>Once successful, you will see the text "Zoom connected."</li>
              <li>The next time you schedule a meeting using Puffin, it will automatically include a Zoom link.</li>
            </ul>

            <h3>Usage</h3>
            <p>
              Puffin currently works with G Suite. To log into Puffin, sign in with your Google account and give Puffin permission to:
            </p>
            <ul>
              <li>View events on all Calendars on your Google account so that it can find free time slots to propose meeting times.</li>
              <li>Edit your Google Calendar so that Puffin can schedule meetings for you in your calendar.</li>
              <li>See your personal info, including any personal info you've made publicly available such as your profile picture.</li>
              <li>See your email address.</li>
            </ul>

            <h5>Schedule meetings with coworkers</h5>
            <p>
              Puffin allows you to schedule meetings with coworkers in under 10 seconds. Puffin looks at each invitee's Google Calendar and proposes the ‘healthiest’ times when everyone is free. When you first log in you'll be asked to set a few preferences that allow you to influence the times that Puffin suggests.
            </p>

            <h5>Meetings with external parties</h5>
            <p>
              For meetings with external parties, Puffin greatly streamlines the back-and-forth communications process allowing you to organize external meetings faster and easier. External users do not have to grant Puffin access to their calendars for scheduling to work, however doing so speeds up the scheduling process.
            </p>

            <h5>Schedule focus time for yourself</h5>
            <p>
              Puffin makes it easy to block out time to focus on deep work.
            </p>

            <h5>Feedback</h5>
            <p>
              At the conclusion of each meeting, Puffin asks attendees to provide feedback, helping to create a culture of continuous improvement.
            </p>

            <h5>Insights</h5>
            <p>
              Puffin examines both your Google Calendar history and the feedback on meetings that you attended in order to provide tips that help you have better meetings, manage your days and improve your organization's meeting culture.
            </p>

            <h3>Revoking Zoom Access</h3>
            <ul>
              <li>Log in to the <a href={appDomain} rel='noopener noreferrer' target='_blank'>Puffin web app</a>.</li>
              <li>
                Navigate to your <a href={`${appDomain}/integrations`} rel='noopener noreferrer' target='_blank'>Integration settings</a>
              </li>
              <li>
                Click Zoom.
              </li>
              <li>
              Click Disconnect.
              </li>
            </ul>

            <h3>Deauthorizing Puffin from Zoom</h3>
            <ul>
              <li>Log in to the Zoom Marketplace <a href={zoomUrl} rel='noopener noreferrer' target='_blank'>here</a>.</li>
              <li>
                Click on ‘Manage’ in the top-right corner of the screen.
              </li>
              <li>
                In the side-panel on the left-side of the screen, click on “Installed Apps”.
              </li>
              <li>
                Find the row that says “Puffin”, click “Uninstall”.
              </li>
            </ul>

            <h3>Contact Support</h3>
            <p>Our head office is based in San Francisco, so expect responses during standard working hours Pacific Time (PST), and a first response within the next business day.</p>
            <h5>iPhone</h5>
            <ul>
              <li>Open the Puffin app on your iPhone</li>
              <li>Click the cog icon to open your preferences</li>
              <li>Tap "Send feedback to Puffin team"</li>
            </ul>
            <h5>Web App</h5>
            <ul>
              <li>Log in to the Puffin web app</li>
              <li>Click your profile avatar to open your account menu</li>
              <li>Click "Contact us"</li>
            </ul>
            <h5>Email</h5>
            <ul>
              <li>You can email us at <a href={`mailto:${helpEmail}`}>{helpEmail}</a></li>
            </ul>
          </S.MaxWidth>
        </S.Wrap>
        <Footer />
      </S.SupportPage>
    )
  }
}
