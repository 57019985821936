export const brand = '#3D87C9'
export const brandHover = '#2A95F4'
export const teal = '#9FC5CC'
export const gray = '#404446'

export const black = '#000'
export const white = '#FFF'

export const black012 = 'rgba(0, 0, 0, 0.12)'

export const darkBlueGray = 'rgba(64, 68, 70, 0.4)'

// Contextual colors
export const errorRed = '#CC4F47'
