import { css, createGlobalStyle } from 'styled-components'

import { Breakpoints, Colors } from 'app/styles'

const sansSerifFallback = "'-apple-system', 'BlinkMacSystemFont', 'Helvetica Neue', 'Roboto', 'sans-serif'"

export const fontFace = `'Avenir Next', ${sansSerifFallback}`
export const fontWeights = {
  regular: 400,
  medium: 500,
  demiBold: 600,
  bold: 'bold'
}

export const body = css`
  font-size: 16px;
  line-height: 150%;
`

export const smallBody = css`
  font-size: 14px;
  line-height: 28px;
  font-weight: 100;
`

export const largeInput = css`
  font-size: 24px;
`

const header1 = css`
  margin: 0;
  padding: 0;
  font-size: 45px;
  line-height: 130%;
  font-weight: ${fontWeights.bold};

  @media(max-width: ${Breakpoints.main}) {
    font-size: 30px;
  }
`

export const GlobalStyle = createGlobalStyle`
  h1 {
    ${header1};
  }

  a {
    color: ${Colors.black};
    text-decoration: none;
    transition: color .3s ease;
    font-weight: ${fontWeights.medium};

    &:hover {
      color: ${Colors.brand};
    }
  }
`
