import React, { Component } from 'react'
import { Privacy } from '@all-turtles/components'

import * as S from './styles'
import { Footer } from 'app/components'

export default class PrivacyPage extends Component {
  render () {
    return (
      <S.PrivacyPage>
        <S.Wrap>
          <S.MaxWidth>
            <S.Title>Privacy</S.Title>
            <Privacy />
          </S.MaxWidth>
        </S.Wrap>
        <Footer />
      </S.PrivacyPage>
    )
  }
}
