import styled, { keyframes } from 'styled-components'

import { Spinner } from 'app/ui-kit/Icons'

const spinAnimation = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledSpinner = styled(Spinner)`
  width: 30px;
  height: 30px;
  animation: ${spinAnimation} 1.5s cubic-bezier(0.645, 0.045, 0.355, 1.000) infinite;
`
