import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import { withRouter } from 'react-router-dom'

import * as S from './styles'
import { puffinRocket } from 'app/assets/images'
import { withForm } from 'app/components'
import { Loading } from 'app/ui-kit'
import Analytics, { analyticsEventTypes } from 'app/analytics'
import fields from './fields'

@withRouter
@withForm(fields)
export default class BetaSignUp extends Component {
  state = {
    loading: null,
    submitSuccess: false
  }

  submitBetaSignUp = async () => {
    const { validate, formState } = this.props
    if (!validate()) return

    const email = formState.email.value

    Analytics.event({
      eventType: analyticsEventTypes.buttonClick,
      pathname: this.props.location.pathname,
      props: {
        buttonName: 'Submit beta sign up Button',
        email
      }
    })

    this.setState({ loading: 1 })
    await setTimeout(() => { this.setState({ submitSuccess: true }) }, 1100)
  }

  render () {
    const { loading, submitSuccess } = this.state
    const onEnterKey = this.submitBetaSignUp

    return (
      <S.BetaSignUpComponent>
        <S.Wrap>
          <S.SubTitle>Puffin will be taking it’s first flight soon...</S.SubTitle>
          <S.StyledPuffinRocket src={puffinRocket} />
          <S.FormWrap>
            <CSSTransition
              timeout={S.transitionDurationMs}
              classNames='form'
              unmountOnExit
              in={!submitSuccess}
            >
              <S.Form>
                {loading && <S.LoadingWrap><Loading /></S.LoadingWrap>}
                <S.FormElements loading={loading}>
                  {this.props.renderField(S.StyledTextField, fields.email, onEnterKey)}
                  <S.StyledButton onClick={this.submitBetaSignUp}>Sign up for Beta</S.StyledButton>
                </S.FormElements>
              </S.Form>
            </CSSTransition>

            <CSSTransition
              timeout={250}
              classNames='success'
              unmountOnExit
              in={submitSuccess}
            >
              <S.Success>Thanks! You’ll get an email as soon as Puffin is available.</S.Success>
            </CSSTransition>
          </S.FormWrap>
        </S.Wrap>
      </S.BetaSignUpComponent>
    )
  }
}
