import React from 'react'
import styled, { css } from 'styled-components'

import { Type, Colors, Shadows } from 'app/styles'

const ButtonWrap = ({ loading, buttonName, staticContext, ...rest }) => (
  <button {...rest} />
)

export const Button = styled(ButtonWrap)`
  font-family: ${Type.fontFace};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: background 275ms ease;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: ${Colors.white};
  background: ${Colors.brand};
  border-radius: 24px;
  box-shadow: ${Shadows.main};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  appearance: none;
  border: 0;

  &:hover {
    background: ${Colors.brandHover};
  }

  &:active {
    background: ${Colors.brand};
  }

  ${props => props.disabled && css`
    opacity: .1;
    pointer-events: none;
  `}
`

export const ButtonText = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-weight: bold;
  width: 100%;
  font-size: 16px;
`
