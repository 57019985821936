import React, { Component } from 'react'

import * as S from './styles'
import { Footer } from 'app/components'

export default class Faq extends Component {
  render () {
    return (
      <S.FaqPage>
        <S.Wrap>
          <S.MaxWidth>
            <S.Title>Frequently asked questions</S.Title>
            <p>Coming soon</p>
          </S.MaxWidth>
        </S.Wrap>
        <Footer />
      </S.FaqPage>
    )
  }
}
