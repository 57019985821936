import styled, { css } from 'styled-components'

import { Colors, Type, Shadows } from 'app/styles'

export const InputWrap = styled.div`
  flex-grow: 1;
  height: 100%;
`

const sharedInputStyles = css`
  padding: 0 20px;
  border-radius: 24px;
  box-sizing: border-box;
  width: 100%;
  font-family: ${Type.fontFace};
  height: 48px;
  border: 3px solid rgba(255, 255, 255, 0);
  transition: all 175ms ease;

  &:focus {
    outline: none;
    border: 3px solid ${Colors.brandHover};
    box-shadow: ${Shadows.main};
  }

  ::placeholder {
    color: ${Colors.lightGray};
  }
`

export const TextInput = styled.input`
  ${Type.body};
  ${sharedInputStyles};
  -webkit-appearance: none;

  ${props => props.error && css`
    border: 3px solid ${Colors.errorRed};

    &:focus {
      border: 3px solid ${Colors.errorRed};
    }
  `}
`

export const Error = styled.div`
  ${Type.smallBody}
  color: ${Colors.red};
  position: relative;
  height: 20px;
  margin: 4px 0 0 4px;
`

export const Label = styled.div`
  ${Type.smallBody}
  left: 2px;
  position: relative;
  height: 20px;
  color: ${Colors.lightGray};
`
