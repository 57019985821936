import React, { Component } from 'react'
import { Copyright } from '@all-turtles/components'

import * as S from './styles'

const footerNavItems = {
  support: {
    display: 'Support',
    path: '/support'
  },
  privacy: {
    display: 'Privacy',
    path: '/privacy'
  },
  termsOfUse: {
    display: 'Terms of Use',
    path: '/terms-of-use'
  }
}

export default class Footer extends Component {
  renderFooterNavItems = () => {
    return Object.values(footerNavItems).map(item => (
      <S.FooterNavItem key={item.path} to={item.path}>{item.display}</S.FooterNavItem>
    ))
  }

  render () {
    return (
      <S.FooterComponent>
        <S.MaxWidth>
          <a href='https://all-turtles.com' target='_blank' rel='noopener noreferrer'>
            <S.StyledAllTurtlesBadge />
          </a>
          <S.Rule />
          <S.FooterNav>
            <S.NavItems>
              {this.renderFooterNavItems()}
              <S.StyledFooterA href='mailto:hello@all-turtles.com'>
                Contact
              </S.StyledFooterA>
            </S.NavItems>
            <a href='https://all-turtles.com' target='_blank' rel='noopener noreferrer'>
              <S.StyledAllTurtlesBadgeMobile />
            </a>
            <S.Copyright><Copyright /></S.Copyright>
          </S.FooterNav>
        </S.MaxWidth>
      </S.FooterComponent>
    )
  }
}
