import styled from 'styled-components'

import { Breakpoints, Sizes, Templates } from 'app/styles'
import { Colors } from '@all-turtles/puffin-web-core'

export const HomePage = styled.div`
  ${Templates.pageWrap};
  background: ${Colors.teal};
  min-height: calc(100vh - 167px);
  flex-direction: column;
  align-items: center;
`

export const MaxWidth = styled.div`
  max-width: ${Sizes.maxWidth};
  width: 100%;
  position: relative;
  margin: auto 0;
  padding-top: 40px;

  @media(max-width: ${Breakpoints.main}) {
    max-width: 340px;
  }
`

export const Title = styled.h1`
  max-width: 580px;
  margin-bottom: 48px;

  @media(max-width: ${Breakpoints.main}) {
    margin-bottom: 30px;
    max-width: 340px;
  }
`

export const StyledPuffinInvite = styled.img`
  width: 148px;
  height: 148px;
  position: absolute;
  top: -110px;
  right: -5px;

  @media(max-width: ${Breakpoints.homeImageResize}) {
    width: 10vw;
    height: 10vw;
    bottom: 25vw;
    top: auto;
  }

  @media(max-width: ${Breakpoints.homeImageResize2}) {
    width: 9vw;
    height: 9vw;
    bottom: 21vw;
  }

  @media(max-width: ${Breakpoints.main}) {
    width: 85px;
    height: 85px;
    top: 0;
    left: 180px;
  }
`

export const StyledPuffinTime = styled.img`
  width: 492px;
  height: 492px;
  position: absolute;
  right: 0;
  top: -36px;

  @media(max-width: ${Breakpoints.homeImageResize}) {
    width: 35vw;
    height: 35vw;
    bottom: -40px;
    top: auto;
  }

  @media(max-width: ${Breakpoints.homeImageResize2}) {
    width: 32vw;
    height: 32vw;
    bottom: -40px;
    top: auto;
  }

  @media(max-width: ${Breakpoints.main}) {
    width: 180px;
    height: 180px;
    position: relative;
    right: 0;
    top: 0;
  }
`
