import React, { Component } from 'react'
import { renderRoutes } from 'react-router-config'

import * as S from './styles'
import '../../styles/FontFaces.css'
import { Nav, AppHelmet } from 'app/components'
import { Global, Type } from 'app/styles'
import ModalController from '../modals/ModalController'

export default class Root extends Component {
  render () {
    const { route } = this.props

    return (
      <S.Wrap>
        <AppHelmet route={route} />
        <Global.GlobalStyle />
        <Type.GlobalStyle />
        <ModalController />
        <Nav />
        {renderRoutes(this.props.route.routes)}
      </S.Wrap>
    )
  }
}
